<template>
  <div>
    <el-dialog title="售后处理" :visible.sync="dialogVisible" @close="onCancel">
      <el-form label-width="150px" label-position="right" :model="localDataForm" ref="ruleForm" :rules="rules">
        <el-row>
          <el-col :span="11">
            <el-form-item label="售后单号">
              <el-input v-model="ruleForm.refundCode" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="退款金额">
              <el-input v-model="ruleForm.refundAmount" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="售后理由">
              <el-input type="textarea" resize="none" :rows="3" v-model="ruleForm.refundMsg" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="处理售后">
              <el-select v-model="handleTypeVal" placeholder="请选择" clearable>
                <el-option v-for="item in handleType" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="handleTypeVal == 1 && ruleForm.orderType == 2">
            <el-form-item label="运费责任">
              <el-select v-model="responsibilityType" placeholder="请选择" clearable>
                <el-option v-for="item in freightType" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="handleTypeVal == 2">
          <el-col :span="22">
            <el-form-item label="拒绝原因" prop="refuseMsg">
              <el-input type="textarea" :maxlength="50" :rows="3" resize="none" placeholder="请输入拒绝原因"
                v-model="localDataForm.refuseMsg" show-word-limit></el-input>
            </el-form-item>

          </el-col>
        </el-row>
        <el-row v-if="handleTypeVal == 1 && responsibilityType == 2">
          <el-col :span="22">
            <el-form-item label="责任说明" prop="responsibilityMsg">
              <el-input type="textarea" :maxlength="250" :rows="5" resize="none" placeholder="请输入运费责任说明"
                v-model="localDataForm.responsibilityMsg" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="handleTypeVal == 1 && responsibilityType == 2">
          <el-form-item class="logo_pic" label="上传图片">
            <div class="pic-row">
              <div class="pic">
                <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                  :show-file-list="false" :on-success="handleSuccess" :before-upload="beforeUploads"
                  :on-remove="handleRemoveLogo" :disabled="picUrls.picUrl1 ? true : false">
                  <img v-if="picUrls.picUrl1" :src="picUrls.picUrl1" class="logoimg" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i v-if="picUrls.picUrl1" class="delpic delpic1 el-icon-error" @click="handleRemoveLogo"></i>
              </div>
              <div class="pic">
                <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                  :show-file-list="false" :on-success="handleSuccess2" :before-upload="beforeUploads"
                  :on-remove="handleRemoveLogo2" :disabled="picUrls.picUrl2 ? true : false">
                  <img v-if="picUrls.picUrl2" :src="picUrls.picUrl2" class="logoimg" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i v-if="picUrls.picUrl2" class="delpic delpic2 el-icon-error" @click="handleRemoveLogo2"></i>
              </div>
              <div class="pic">
                <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                  :show-file-list="false" :on-success="handleSuccess3" :before-upload="beforeUploads"
                  :on-remove="handleRemoveLogo3" :disabled="picUrls.picUrl3 ? true : false">
                  <img v-if="picUrls.picUrl3" :src="picUrls.picUrl3" class="logoimg" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i v-if="picUrls.picUrl3" class="delpic delpic3 el-icon-error" @click="handleRemoveLogo3"></i>
              </div>
            </div>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain @click="onCancel">取 消</el-button>
        <el-button type="primary" size="small" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>


</template>

<script>
export default {
  props: {
    // 接受父级组件的显示标记，用于被watch
    showFlag: {
      type: Boolean
    },
    ruleForm: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      // 处理售后类型
      handleType: [
        {
          value: '1',
          label: '同意退款'
        }, {
          value: '2',
          label: '拒绝退款'
        },
      ],
      // 运费责任
      freightType: [
        {
          value: '1',
          label: '商家责任（商家承担运费）'
        }, {
          value: '2',
          label: '骑手责任（骑手承担运费，需平台审核）'
        },
      ],
      //  prop 值的局部数据属性  
      localDataForm: this.ruleForm,
      rules: {
        refuseMsg: [
          { required: true, message: '请输入拒绝原因', trigger: 'blur' },
        ],
        responsibilityMsg: [
          { required: true, message: '请输入运费责任说明', trigger: 'blur' },
        ],
      },
      // 图片上传
      picUrls: {
        picUrl1: "",
        picUrl2: "",
        picUrl3: "",
      },
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      handleTypeVal: '',//处理售后
      responsibilityType: '',//运费责任
      picUrl: [],
    }
  },
  watch: {
    // 观察父级组件的传值
    showFlag(newVal) {
      this.dialogVisible = newVal
    },
  },
  created() {

  },
  methods: {
    // 删除
    handleRemoveLogo() {
      this.picUrls.picUrl1 = ""
    },
    // 删除
    handleRemoveLogo2() {
      this.picUrls.picUrl2 = ""
    },
    // 删除
    handleRemoveLogo3() {
      this.picUrls.picUrl3 = ""
    },
    // 上传成功
    handleSuccess(res) {
      const { code, result } = res
      if (code == 100) {
        this.picUrls.picUrl1 = result.fileUrl
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 上传成功
    handleSuccess2(res) {
      const { code, result } = res
      if (code == 100) {
        this.picUrls.picUrl2 = result.fileUrl
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 上传成功
    handleSuccess3(res) {
      const { code, result } = res
      if (code == 100) {
        this.picUrls.picUrl3 = result.fileUrl
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 上传前检查
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
    // 弹窗输入(优化)
    async submitForm(formName) {
      // console.log('ruleForm', this.ruleForm);
      try {
        // 校验表单 
        await new Promise((resolve, reject) => {
          this.$refs[formName].validate(valid => {
            if (!valid) {
              this.handleTypeVal == 2 ? this.$message.error('拒绝理由不能为空！') : this.$message.error('运费责任说明不能为空！');
              reject(new Error('表单校验失败')); // 拒绝Promise并传递错误  
            } else {
              resolve(); // 校验成功，解决Promise  
            }
          });
        });
        this.picUrl = [this.picUrls.picUrl1 || null, this.picUrls.picUrl2 || null, this.picUrls.picUrl3 || null];
        // 准备要发送的数据  
        const data = {
          orderReturnId: this.ruleForm.orderRefundId,
          responsibilityMsg: this.handleTypeVal == 2 ? null : this.localDataForm.responsibilityMsg,//责任说明 
          refuseMsg: this.localDataForm.refuseMsg || null,//拒绝原因
          status: this.handleTypeVal,//状态：1成功（通过）；2售后拒绝
          responsibilityType: this.ruleForm.orderType == 3 ? 1 : this.handleTypeVal == 2 ? null : this.responsibilityType,//责任类型:1商家责任 , 2骑手责任
          picUrl: this.picUrl, //图片 
        };
        // console.log('准备要发送的数据 ', data);
        if (this.handleTypeVal) {
          if (this.handleTypeVal == 1 && this.ruleForm.orderType == 2 && this.responsibilityType == '') {
            this.$message.error('请选择运费责任类型！');
            return
          }
          // 根据状态决定是退款还是拒绝退款
          let response = await this.$axios.post(this.$api.ordersRefundUpdate, data);
          // 处理响应结果
          const { code } = response.data;
          if (code == 100) {
            this.$message({ message: '恭喜你，处理成功!', type: 'success' });
            this.onCancel();//重置+关闭弹窗 
          }
        } else {
          this.$message.error('请选择售后处理类型！');
        }

      } catch (error) {
        console.log(error);
      }
    },
    // 检验重置
    resetForm() {
      this.handleTypeVal = '';//处理类型恢复
      this.responsibilityType = '';//重置
      this.localDataForm = {};//清空对象
      this.picUrls = {
        picUrl1: "",
        picUrl2: "",
        picUrl3: "",
      };//清空对象
      this.$refs.ruleForm.resetFields();
    },

    // 触发父级组件的closeChildDialog
    onCancel() {
      this.resetForm()//重置校验
      this.$emit('closeChildDialog')
    },
  },
}
</script>

<style scoped lang='scss'>
// 图片
.logo_pic {
  position: relative;

  ::v-deep .el-form-item__content {
    width: 100px;
    height: 100px;
  }

  .pic-row {
    display: flex;

    .pic {
      margin-right: 15px;

      .logo_uploader {
        ::v-deep .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        ::v-deep .el-upload:hover {
          border-color: #069bbc;
        }

        .avatar-uploader-icon {
          font-size: 28px;
          color: #069bbc;
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }

        .logoimg {
          width: 100px;
          height: 100px;
          display: block;
          object-fit: cover;
        }
      }
    }
  }

  .delpic {
    position: absolute;
    top: -12px;
    color: red;
    font-size: 20px;
    cursor: pointer;
  }

  .delpic1 {
    right: -15px;
  }

  .delpic2 {
    right: -134px;
  }

  .delpic3 {
    right: -250px;
  }
}
</style>

<!-- 自提详情 -->
<template>
  <div>
    <back />
    <div class="det">
      <!-- 基本信息 -->
      <div class="detail">
        <h4 class="title">详情信息：</h4>
        <el-row class="row">
          <el-col :span="5">
            <div class="col order-code">售后单号：{{ basicInfo.refundCode }}</div>
            <div class="col">平台处理状态：
              <span v-if="basicInfo.platformStatus == null">-</span>
              <span v-else>
                {{ getOrderPlatformStatusText(basicInfo.platformStatus) }}
              </span>
            </div>

            <div class="col">售后原因：{{ basicInfo.refundMsg }}</div>
            <!-- 拒绝原因：拒绝退款状态显示 -->
            <div class="col" v-if="basicInfo.refuseMsg">拒绝原因：
              <span style="color: red;">{{ basicInfo.refuseMsg }}</span>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="col">售后类型：{{ getStatusText(basicInfo.refundType) }}</div>
            <div class="col">下单时间：{{ basicInfo.orderTime }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">订单编号：{{ basicInfo.orderCode }}</div>
            <div class="col">申请时间：{{ basicInfo.returnTime }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">退款金额：{{ basicInfo.refundAmount }}元</div>
            <div class="col" v-if="basicInfo.completionAfterSalesTime">售后时间：
              {{ basicInfo.completionAfterSalesTime }}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="col">商家处理状态：<span :style="{ color: basicInfo.status == 2 ? 'red' : '' }">{{
              getOrderStatusText(basicInfo.status) }}</span></div>
          </el-col>
        </el-row>
        <el-row class="row" v-if="basicInfo.orderSource == 2">
          <el-col>
            <div class="col">备注：{{ basicInfo.buyerNotes || '-' }}</div>
          </el-col>
        </el-row>
      </div>
      <!-- 用户信息 -->
      <div class="detail" v-if="basicInfo.orderSource == 2">
        <h4 class="title">用户信息：</h4>
        <el-row class="row">
          <el-col :span="4">
            <div class="col">用户编号：{{ basicInfo.userCode || '-' }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">昵称：{{ basicInfo.userName || '-' }}</div>
          </el-col>
        </el-row>
      </div>
      <!-- 骑手信息 -->
      <div class="detail" v-if="basicInfo.orderSource == 2">
        <h4 class="title">骑手信息：</h4>
        <el-row class="row">
          <el-col :span="4">
            <div class="col">骑手编号：{{ basicInfo.riderCode || '-' }}</div>
            <div class="col">取货时间：{{ basicInfo.deliveryTime || '-' }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">骑手名称：{{ basicInfo.riderName || '-' }}</div>
            <div class="col">送达时间：{{ basicInfo.orderCompletionTime || '-' }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">手机号码：{{ basicInfo.riderPhone || '-' }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">接单时间：{{ basicInfo.riderOrderTime || '-' }}</div>
          </el-col>
        </el-row>
      </div>
      <!-- 收货信息 -->
      <div class="detail" v-if="basicInfo.orderSource == 2">
        <h4 class="title">收货信息：</h4>
        <el-row class="row">
          <el-col :span="4">
            <div class="col">收货人姓名：{{ basicInfo.consigneeName }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">收货人号码：{{ basicInfo.contactPhone }}</div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col>
            <div class="col">收货地址：{{ basicInfo.address }}</div>
          </el-col>
        </el-row>
      </div>
      <!-- 商品信息 -->
      <div class="detail">
        <div class='h4-btn'>
          <h4 class="title">商品信息：</h4>
          <el-button type="primary" @click="handleHandle" v-if="basicInfo.status == 0">处理售后</el-button>
        </div>
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table :data="tableData">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="productNum" label="商品编号" show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="specs" label="商品规格" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="unitPrice" label="单价(元)" show-overflow-tooltip min-width="110" align="center">
            </el-table-column>
            <el-table-column prop="quantity" label="数量" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="refundAmount" label="退款金额(元)" show-overflow-tooltip min-width="130" align="center"
              fixed="right">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 售后处理弹窗 -->
    <child :showFlag="showFlag" @closeChildDialog="closeChildDialog" :ruleForm="basicInfo"></child>
  </div>
</template>

<script>
import child from './dialog.vue'
export default {
  components: {
    child,
  },
  data() {
    return {
      loading: true,
      showFlag: false,//售后弹窗显隐
      basicInfo: {},//基本信息
      tableData: [],
    }
  },

  created() {
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.ordersRefundDet, {
        params: {
          orderRefundId: this.$route.query.orderRefundId,//售后id
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          // console.log('result', result); 
          this.basicInfo = result
          this.tableData = result.orderReturnProductVoList
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })
    },
    // 售后类型 售后：1退货退款；2退款
    getStatusText(status) {
      switch (String(status)) {
        case '1':
          return '退货退款';
        case '2':
          return '仅退款';
        default:
          return;
      }
    },
    // 订单状态 状态：-1撤销；0处理中；1成功；2售后拒绝；3、等待平台退款
    getOrderStatusText(status) {
      switch (String(status)) {
        case '-1':
          return '已撤销';
        case '0':
          return '待处理';
        case '1':
          return '已退款';
        case '2':
          return '售后拒绝';
        case '3':
          return '待平台处理';
        default:
          return;
      }
    },
    // 平台审核:-1无需审核,0.待处理,1.成功,2.失败 
    getOrderPlatformStatusText(status) {
      switch (String(status)) {
        case '-1':
          return '-';
        case '0':
          return '待处理';
        case '1':
          return '审核成功';
        case '2':
          return '审核失败';
        default:
          return;
      }
    },
    handleHandle() {
      this.showFlag = true
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog() {
      this.showFlag = false
      this.getRequest()
    },
  },
}
</script>

<style scoped lang='scss'>
.det {
  padding: 0 30px;

  .detail {
    padding-top: 20px;
    border-bottom: 1px solid rgba(3, 16, 14, 0.06);

    .h4-btn {
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      margin-bottom: 15px;
    }

    .row {
      color: #707070;
      // font-size: 16px;

      .col {
        margin-bottom: 20px;
        // margin-right: 10px;
        font-size: 16px;
      }

      .order-code {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }


  }


}
</style>